exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-privacy-js": () => import("./../../../src/pages/data-privacy.js" /* webpackChunkName: "component---src-pages-data-privacy-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-form-js": () => import("./../../../src/pages/order-form.js" /* webpackChunkName: "component---src-pages-order-form-js" */),
  "component---src-slides-bei-informationen-zaehlt-verlaesslichkeit-js": () => import("./../../../src/slides/Bei-informationen-zaehlt-verlaesslichkeit.js" /* webpackChunkName: "component---src-slides-bei-informationen-zaehlt-verlaesslichkeit-js" */),
  "component---src-slides-der-zeitung-schenkt-man-glauben-js": () => import("./../../../src/slides/Der-zeitung-schenkt-man-glauben.js" /* webpackChunkName: "component---src-slides-der-zeitung-schenkt-man-glauben-js" */),
  "component---src-slides-die-mehrheit-liest-zeitung-js": () => import("./../../../src/slides/Die-Mehrheit-liest-Zeitung.js" /* webpackChunkName: "component---src-slides-die-mehrheit-liest-zeitung-js" */),
  "component---src-slides-die-zeitung-ist-ein-universalmedium-js": () => import("./../../../src/slides/Die-zeitung-ist-ein-universalmedium.js" /* webpackChunkName: "component---src-slides-die-zeitung-ist-ein-universalmedium-js" */),
  "component---src-slides-fake-news-erfahrungen-js": () => import("./../../../src/slides/Fake-News-Erfahrungen.js" /* webpackChunkName: "component---src-slides-fake-news-erfahrungen-js" */),
  "component---src-slides-fake-news-im-internet-js": () => import("./../../../src/slides/Fake-News-Im-Internet.js" /* webpackChunkName: "component---src-slides-fake-news-im-internet-js" */),
  "component---src-slides-groesser-ist-besser-js": () => import("./../../../src/slides/Groesser-ist-besser.js" /* webpackChunkName: "component---src-slides-groesser-ist-besser-js" */),
  "component---src-slides-in-der-zeitung-ist-werbung-wichtig-js": () => import("./../../../src/slides/In-der-zeitung-ist-werbung-wichtig.js" /* webpackChunkName: "component---src-slides-in-der-zeitung-ist-werbung-wichtig-js" */),
  "component---src-slides-in-zeitungen-sind-prospekte-relevant-js": () => import("./../../../src/slides/In-zeitungen-sind-prospekte-relevant.js" /* webpackChunkName: "component---src-slides-in-zeitungen-sind-prospekte-relevant-js" */),
  "component---src-slides-konsumenten-informieren-sich-aktiv-in-der-zeitung-js": () => import("./../../../src/slides/Konsumenten-informieren-sich-aktiv-in-der-zeitung.js" /* webpackChunkName: "component---src-slides-konsumenten-informieren-sich-aktiv-in-der-zeitung-js" */),
  "component---src-slides-kundenapps-sind-praktisch-prospekte-stiften-nutzen-js": () => import("./../../../src/slides/Kundenapps-sind-praktisch-prospekte-stiften-nutzen.js" /* webpackChunkName: "component---src-slides-kundenapps-sind-praktisch-prospekte-stiften-nutzen-js" */),
  "component---src-slides-leser-teilen-sich-ihre-zeitung-js": () => import("./../../../src/slides/Leser-teilen-sich-ihre-zeitung.js" /* webpackChunkName: "component---src-slides-leser-teilen-sich-ihre-zeitung-js" */),
  "component---src-slides-menschen-haben-immer-das-letzte-wort-js": () => import("./../../../src/slides/Menschen-haben-immer-das-letzte-wort.js" /* webpackChunkName: "component---src-slides-menschen-haben-immer-das-letzte-wort-js" */),
  "component---src-slides-ob-print-oder-epaper-js": () => import("./../../../src/slides/Ob-print-oder-epaper.js" /* webpackChunkName: "component---src-slides-ob-print-oder-epaper-js" */),
  "component---src-slides-relevanz-und-wertschaetzung-js": () => import("./../../../src/slides/Relevanz-und-wertschaetzung.js" /* webpackChunkName: "component---src-slides-relevanz-und-wertschaetzung-js" */),
  "component---src-slides-tageszeitungen-sind-uns-am-naechsten-js": () => import("./../../../src/slides/Tageszeitungen-sind-uns-am-naechsten.js" /* webpackChunkName: "component---src-slides-tageszeitungen-sind-uns-am-naechsten-js" */),
  "component---src-slides-webseiten-der-regionalzeitungen-js": () => import("./../../../src/slides/Webseiten-der-Regionalzeitungen.js" /* webpackChunkName: "component---src-slides-webseiten-der-regionalzeitungen-js" */),
  "component---src-slides-werbung-ist-gestaltungshilfe-fuer-den-einkaufszettel-js": () => import("./../../../src/slides/Werbung-ist-gestaltungshilfe-fuer-den-einkaufszettel.js" /* webpackChunkName: "component---src-slides-werbung-ist-gestaltungshilfe-fuer-den-einkaufszettel-js" */),
  "component---src-slides-werbung-und-lokalberichterstattung-js": () => import("./../../../src/slides/Werbung-und-lokalberichterstattung.js" /* webpackChunkName: "component---src-slides-werbung-und-lokalberichterstattung-js" */),
  "component---src-slides-zeitungen-erreichen-alle-bevoelkerungsgruppen-js": () => import("./../../../src/slides/Zeitungen-erreichen-alle-bevoelkerungsgruppen.js" /* webpackChunkName: "component---src-slides-zeitungen-erreichen-alle-bevoelkerungsgruppen-js" */),
  "component---src-slides-zeitungen-gewinnen-online-junge-leser-js": () => import("./../../../src/slides/Zeitungen-gewinnen-online-junge-leser.js" /* webpackChunkName: "component---src-slides-zeitungen-gewinnen-online-junge-leser-js" */),
  "component---src-slides-zeitungen-sind-lokale-informationsquellen-nummer-eins-js": () => import("./../../../src/slides/Zeitungen-sind-lokale-informationsquellen-nummer-eins.js" /* webpackChunkName: "component---src-slides-zeitungen-sind-lokale-informationsquellen-nummer-eins-js" */),
  "component---src-slides-zeitungen-sind-mehr-als-print-js": () => import("./../../../src/slides/Zeitungen-sind-mehr-als-print.js" /* webpackChunkName: "component---src-slides-zeitungen-sind-mehr-als-print-js" */),
  "component---src-slides-zeitungen-werden-intensiv-genutzt-js": () => import("./../../../src/slides/Zeitungen-werden-intensiv-genutzt.js" /* webpackChunkName: "component---src-slides-zeitungen-werden-intensiv-genutzt-js" */),
  "component---src-slides-zeitungsleser-sind-ganz-bei-der-sache-js": () => import("./../../../src/slides/Zeitungsleser-sind-ganz-bei-der-sache.js" /* webpackChunkName: "component---src-slides-zeitungsleser-sind-ganz-bei-der-sache-js" */),
  "component---src-slides-zeitungsqualitaeten-2025-js": () => import("./../../../src/slides/Zeitungsqualitaeten-2025.js" /* webpackChunkName: "component---src-slides-zeitungsqualitaeten-2025-js" */)
}

